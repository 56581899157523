"use client";
import Image from "next/image";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Certificate = ({props}:any) => {
  const settings = {
    dots: false,
    infinite: true, // Makes it circular
    speed: 1000, // Transition speed
    slidesToShow: 5, // Number of visible slides at a time
    slidesToScroll: 1, // Number of slides moved per transition
    autoplay: true, // Enables auto-sliding
    autoplaySpeed: 1500, // Time between transitions
    arrows: false, 
    
    pauseOnHover: true,
    // Removes navigation arrows
    responsive: [
      {
        breakpoint: 1224, // Tablet
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1024, // Tablet
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768, // Mobile
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 476, // Mobile
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="w-full border-b-[1px] border-lightblue pt-12 md:py-12 ">
      <div className="w-full xl:w-[95%] 2xl:w-[80%] mx-auto px-4 lg:px-[30px]">
        <div className="text-homeblack leading-none lg:leading-[45px]  capitalize font-bold" dangerouslySetInnerHTML={{__html:props ||'<h2> Featured Awards & Recognitions</h2>'}}/>
        
        {/* </div> */}

        {/* React Slick Slider */}
        <div className="mt-10">
          <Slider {...settings}>
            {[1, 2, 3, 4, 5].map((elem: any, index: number) => (
              <div key={index} className="px-2">
                <Image
                  src={`/award/award${elem}.svg`}
                  width={400}
                  height={300}
                  alt="Awards"
                  className="cursor-pointer mx-auto"
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Certificate;
