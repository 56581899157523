"use client";
import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import Button from '@/components/CommonComponent/button';
import Link from 'next/link';
import ClientsCount from '@/components/CommonComponent/ClientsCount';
import { StyledWrapper } from '@/components/Styled';

const Choose = ({ props }: any) => {
  const [data, setData] = useState({ first: '', second: '' });

  function splitStringByLastWords(text: string, numOfWords: number) {
    const words = text.split(' ');
    if (numOfWords >= words.length) {
      return { first: '', second: text };
    }
    const splitIndex = words.length - numOfWords;
    return {
      first: words.slice(0, splitIndex).join(' '),
      second: words.slice(splitIndex).join(' '),
    };
  }

  useEffect(() => {
    const result = splitStringByLastWords(props?.title || '', 2);
    setData(result);
  }, [props]);

  return (
    <div className="w-full bg-white py-8 2xl:py-20">
      <div className="mb-10 md:w-[80%] px-4 2xl:w-[50%] text-center mx-auto pt-4 flex flex-col gap-3">
        <h2 className="text-center font-bold">
          {data.first} <span className="text-pink">{data.second}</span>
        </h2>
        <StyledWrapper>
          <p
            className="text-[16px] lg:text-[18px] text-center text-homegrey mt-4 font-normal leading-tight"
            dangerouslySetInnerHTML={{
              __html: props?.description || props?.subtitle,
            }}
          />
        </StyledWrapper>
      </div>
      
      <div className="w-full px-4 xl:w-[95%] 2xl:w-[80%] mx-auto flex justify-center gap-5 flex-wrap">
        <div className="flex flex-wrap w-fit gap-5">
          {props?.cards?.map((card: any, index: number) => (
            <div key={index} className="flex-1 minh-[150px] 2xl:min-h-[200px] mt-20 w-full min-w-[90%] sm:min-w-[45%] lg:min-w-[30%]">
              <div className="duration-300 ease-in-out hover:shadow-xl border-[2px] relative border-lightblue bg-white rounded-xl group leading-[29px] p-10 flex flex-col gap-3 h-full">
                <div className="bg-lightblue absolute -top-14 left-[37%] md:left-8 rounded-xl p-4 w-[110px] h-[90px] flex justify-center items-center">
                  <Image src={card.icon} alt={card.icon_alt || 'why choose w3era'} height={62} width={62} />
                </div>
                <p className=" text-center md:text-left  font-bold">{card.title}</p>
                <StyledWrapper>
                  <div
                    className="text-[16px] text-center md:text-left text-homegrey leading-tight"
                    dangerouslySetInnerHTML={{ __html: card.description }}
                  />
                </StyledWrapper>
              </div>
            </div>
          ))}

          {props?.card?.map((card: any, index: number) => (
            <div key={index} className="flex-1 minh-[150px] 2xl:min-h-[200px]  mt-16 2xl:mt-20 min-w-full sm:min-w-[45%] lg:min-w-[30%]">
              <div className="duration-300 ease-in-out hover:shadow-xl border-[2px] relative border-lightblue bg-white rounded-xl group leading-[29px]
              p-4 2xl:p-10 flex flex-col gap-3 h-full">
                <div className="bg-lightblue absolute -top-10 2xl:-top-14 left-[37%] md:left-8 rounded-xl  p-2 2xl:p-4 w-[70px] h-[70px] 2xl:w-[110px] 2xl:h-[90px] flex justify-center items-center">
                  <Image src={card.icon || ''} alt={card.icon_alt || 'why choose w3era'} height={62} width={62} className='w-[42px] h-[42px] 2xl:w-[62px] 2xl:h-[62px]' />
                </div>
                <p className=" text-center  mt-4 md:text-left  font-bold">{card.title}</p>
                <StyledWrapper>
                  <div
                    className="text-[16px] text-center md:text-left text-homegrey leading-tight"
                    dangerouslySetInnerHTML={{ __html: card.description }}
                  />
                </StyledWrapper>
              </div>
            </div>
          ))}
      <div 
  className={`mx-auto 
    ${props?.card?.length==4
      ? ' h-fit  max-h-[150px] sm:h-[284px] lg:min-w-[65%]'
      : '  max-h-[150px] sm:h-[284px] lg:min-w-[30%] flex-1'}
    sm:min-w-[45%] flex justify-center items-center ${(props?.card?.length==6||props?.cards?.length==6)?"mt-8":"mt-8 2xl:mt-24"}`}>
  <div className="flex flex-col justify-center items-center gap-2">
    <Link href="/contact-us">
      <Button content="Contact Us" />
    </Link>
    <ClientsCount />
  </div>
</div>
        </div>
        
      </div>
    </div>
  );
};

export default Choose;
